




































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Dashboard extends Vue {
  public isShow: Boolean = false;

  close() {
    this.isShow = false;
  }
}
